// We import our styles here, webpack will do the rest
import styles from '../scss/app.scss'

import '@babel/polyfill';
// lazy sizes for lazy loading images
import Lazysizes from 'lazysizes'
import _ from 'lodash'
import 'picturefill'

// Our own helpers
import './helpers/classList'
import './helpers/iePolyfills'
import './helpers/objectfitFallback'
import './components/toggle'
import './components/headroom'
import './components/cookieBanner'
import './components/carousel'
import './components/lockTrigger'
import './components/preventZoom'
import reframe from './helpers/iframe'

// Our own components
import primaryNavigation from './components/primaryNavigation'
import footerNavigation from './components/footerNavigation'
import './components/slideshow'

import './components/searchCommon';
import './components/generalSearch';
import './components/searchPageSearch';
import './components/officeSearch';
import './components/modal';

import tabs from './components/tabs';

document.addEventListener('lazybeforeunveil', function (e) {
  if (e.target.tagName === 'IFRAME') {
    reframe(e.target);
  }
});

// Window onload (after all dom and images are loaded completely)
window.onload = function () {
  if (location.hash) {
    let elId = location.hash.replace('#', '');
    let scrollToEl = document.getElementById(elId);

    const yCoordinate = scrollToEl.getBoundingClientRect().top + window.pageYOffset;
    let myElement = document.querySelector("#primary-navigation");

    const yOffset = 0 - myElement.offsetHeight;

    window.scrollTo({
      top: yCoordinate + yOffset,
      behavior: 'smooth'
    });
  }

  // Page has loaded, remove loading class
  document.getElementsByTagName('body')[0].classList.remove('loading')

  // Responsive tables
  // wrap tables in wysiwyg in a div for styling
  const tables = document.querySelectorAll('.wysiwyg table')
  if (tables.length) {
    if (!('length' in tables)) tables = [tables]
    for (let i = 0; i < tables.length; i += 1) {
      const table = tables[i]

      const div = document.createElement('div')
      div.className = 'table-container'

      table.parentNode.insertBefore(div, table)
      table.parentNode.removeChild(table)
      div.appendChild(table)
    }
  }

}

// Function to replace YouTube URLs with nocookie version
function replaceYouTubeNocookie() {
  // Get all iframe elements on the page
  var iframes = document.getElementsByTagName('iframe');

  if (iframes) {
    // Loop through each iframe
    for (var i = 0; i < iframes.length; i++) {
      var iframe = iframes[i];
      var src = iframe.src;

      // Check if the iframe's src contains "youtube.com"
      if (src.includes("youtube.com")) {
        // Replace "youtube.com" with "youtube-nocookie.com"
        var newSrc = src.replace("youtube.com", "youtube-nocookie.com");
        iframe.src = newSrc;
      }
    }
  }
}


replaceYouTubeNocookie();
primaryNavigation();
footerNavigation();
tabs();

const dropdownFunction = (toggleButtonSelector, dropdownSelector, closeButtonSelector) => {
  const toggleButtons = document.querySelectorAll(toggleButtonSelector);
  const dropdown = document.querySelector(dropdownSelector);
  const toggleDropdown = () => {
    if (dropdown.style.display === 'block') {
      dropdown.style.display = "none";
      document.removeEventListener('click', closeDropdown);
    } else {
      dropdown.style.display = 'block';
      document.addEventListener('click', closeDropdown);

      if (dropdownSelector == '#search-dropdown') {
        document.getElementById('dropdown-search').focus();
      }
    }
  }
  const closeDropdown = (event) => {
    if (event.target.closest(toggleButtonSelector) || event.target.classList.contains('preventClose')) return;
    if (!event.target.closest(dropdownSelector) || !!event.target.closest(closeButtonSelector)) {
      dropdown.style.display = "none";
      document.removeEventListener('click', closeDropdown);
    }
  }

  _.forEach(toggleButtons, function (toggleButton) {
    toggleButton.addEventListener('click', toggleDropdown);
  });
}

// USER DROPDOWN
dropdownFunction('#user-dropdown-button', '#user-dropdown');
// REGION DROPDOWN
dropdownFunction('#region-dropdown-button', '.region-dropdown');
// MOBILE NAV
dropdownFunction('#hamburger', '#nav-dropdown', '#nav-close');
// HEADER SEARCH
dropdownFunction('.toggle-nav-dropdown', '#nav-dropdown');