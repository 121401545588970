<template>
  <nav class="content-wrapper mx-auto pt-3" aria-label="Pagination">
    <ul v-if="pages > 1" class="pt-6 list-reset flex items-center justify-center small-text line-height-none">
      <li v-if="current > 1" class="cursor-pointer" @click="changePage(current - 1)">
        <span class="transform-left pr-3 inline-block text-blue hover:text-blue-light">
          <svg class="fill-current block" width="16px" height="16px" viewBox="0 0 768 768">
            <path d="M236.383 567.534c12.158 12.158 12.158 26.115 0 38.275s-26.115 12.158-38.521-0.258c-120.337-132.524-183.641-202.157-185.038-203.556-4.631-4.631-6.804-11.144-6.804-19.139 0-7.991 2.168-14.506 6.56-18.883l185.525-204.065c4.631-4.631 11.144-6.804 19.138-6.804s14.506 2.168 19.138 6.804c12.158 12.158 12.158 26.115 0.253 38.004l-143.963 159.001h643.369c16.447 0 25.939 9.496 25.939 25.94s-9.496 25.94-25.939 25.94h-643.369l143.709 158.737z"></path>
          </svg>
        </span>
      </li>
      <template v-for="n in (current - 2, current + 2)" >
        <li class="cursor-pointer" :key="n" v-if="n > 0 && n >= current - 2 && n <= pages" @click="changePage(n)">
          <span :class=" n === current ? 'text-blue' : 'text-blue-light' " :rel="n > current ? 'next' : n < current ? 'prev' : false" class="px-3 hover:text-blue-light">{{ n }}</span>
        </li>
      </template>
      <li v-if="current < pages" class="cursor-pointer" @click="changePage(current + 1)">
        <span class="transform-right pl-3 inline-block text-blue hover:text-blue-light">
          <svg class="fill-current block" width="16px" height="16px" viewBox="0 0 768 768">
            <path d="M531.618 200.469c-12.16-12.16-12.16-26.115 0-38.276s26.115-12.16 38.521 0.257c120.337 132.524 183.641 202.156 185.038 203.557 4.631 4.631 6.803 11.144 6.803 19.138s-2.17 14.506-6.56 18.881l-185.526 204.067c-4.631 4.631-11.143 6.803-19.138 6.803s-14.506-2.17-19.138-6.803c-12.16-12.16-12.16-26.115-0.254-38.005l143.963-159.002h-643.369c-16.446 0-25.938-9.496-25.938-25.938s9.496-25.938 25.938-25.938h643.369l-143.709-158.738z"></path>
          </svg>
        </span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    pages: {
      type: Number,
      default: 1,
    },
    current: {
      type: Number,
      default: 1,
    }
  },
  methods: {
    changePage(page) {
      this.$emit('update:current', page)
    }
  },
}
</script>
